import { customBaseQuery } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

const TAG_TYPES = [
  'Batches',
  'Batch',
  'Bestsellers',
  'KeyMetricsKPI',
  'WeeklyPackKPI',
  'Products',
  'ProductSkuLocation',
  'LocationSku',
  'Location',
  'LocationProducts',
  'LocationDepartments',
  'ProductLocations',
  'ProductSkuLocations',
  'AssortmentRemovals',
  'LocationDepartmentSku',
  'ProductStockAnalysis',
  'GetFilterTemplates',
  'ChildPeakView',
  'ProposalLocationPivot',
  'ReplenPivotKpis',
];

export const replenishmentApi = createApi({
  reducerPath: 'replenishmentApi',
  tagTypes: TAG_TYPES,
  baseQuery: async (args, api, extraOptions) =>
    // custom query adds auth headers and error handling
    customBaseQuery(args, api, extraOptions, 'v2/replenishment'),
  endpoints: () => ({}),
});
